@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.settings-container {
    padding-bottom: 40px;
    label, .radio-input {
        cursor: pointer;
    }
    
    .content-loading{
        display: flex;
        justify-content: center;
    }
    .title {
        margin: 24px 0 16px 0;
      @include text(600, 18px, 24px, $spb-900-color);
    }
    .label {
        // margin: 16px 0 8px 0;
      @include text(400, 14px, 16px, $dark-100);
    }
    .form-control {
        height: 40px !important;
    }
    .select-country {
        width: 320px;
    }
    .preferences {
        display: flex;
    }
    .preference {
        margin-bottom: 16px;
      @include text(400, 14px, 16px, $dark-200);
     }
     .preference-select {
         margin-left: 24px;
     }
    .settlement {
        padding: 0 0 32px 0;
        margin-top: 24px;
        border-top: 1px solid $gray-100;
        border-bottom: 1px solid $gray-100;
    }
    
 }
 .preference-switch-container{
    .modal-title {
        @include text(600, 18px, 24px, $spb-900-color);
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px
     }
    .switch-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .switch-img {
        margin: 54px 0 32px 0;
        height: 100px;
        width: 100px;
        background-color: $spb-100-color;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center
    }
    .switch {
      @include text(600, 20px, 16px, $dark-200);
    }
    .switch-desc {
        margin: 24px 0 52px 0;
        text-align: center;
      @include text(400, 16px, 16px, $spb-900-color);
    }
    .switch-cancel {
        margin-top: 8px;
        width: 100%;
        background-color: $spb-100-color;
    }
    .open-btn{
        width: 100%;
    }
    
 }