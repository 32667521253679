@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";

.webhook-notification-modal-container {
  .modal-header{
    border-bottom: none
  }
  .renotify {
   @include text(600, 20px, 20px, $black);
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }
  .title {
    margin: 62px 0 16px 0;
   @include text(600, 20px, 20px, $black);
  }
  .description{
    margin-bottom: 40px;
    text-align: center;
    @include text(400, 16px, 24px, $spn-900-color);
  }
   .submit-btn{
     width: 100%;
     height: 40px;
     border-radius: 4px;
   }
   .web-icon {
     min-height: 100px;
     min-width: 100px;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: $spb-100-color;
   }
   .cancel-btn {
    border-radius: 4px;
    background: $spb-100-color;
    margin-top: 8px;
    width: 100%;
    height: 40px;
    border: none;
   @include text(600, 16px, 24px, $black);
   }
   .modal-header{
     padding: 28px 24px 4px 14px !important;
   }
   .modal__body{
     padding-bottom: 30px !important;
   }
   }