@import "../../../assets/styles/common/colors.scss";
@import "~vgg-ui-react/dist/styles/partials";
@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";

.link-modal-wrapper {
  .link-form-content{
    margin-top: -20px;
  }
  .submit-btn {
    width: 100%;
    height: 40px;
    border: 0;
    color: $white;
  }
  .invalid-message {
  @include text(600, 12px, 16px, $spr-700-color);
  }

  .f-12 {
    font-size: 12px;
  }
  .title{
    @include text(600, 20px, 16px, $black);
}
label{
  @include text(400, 14px, 16px, $spn-700-color);
  margin-top: 16px;
}
input {
  height: 48px;
}
.css-319lph-ValueContainer, .css-6j8wv5-Input{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.form-check {
  display: flex;
  align-items: center;
  label{
    margin-top: 5px;
  }
  margin:16px 0 24px 0;
}

}
