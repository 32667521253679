@import "./assets/styles/common/colors.scss";
@import "./assets/styles/common/mixins.scss";

.cursor {
  cursor: pointer;
}

th{
  @include text(600, 14px, 16px, $spb-900-color);
}
thead, tr {
  border: none !important
}
td{
  @include text(400, 14px, 16px, $spb-900-color);
}
.table.default-cell td, .table.default-cell th {
  height: 48px !important;
  padding: 16px 12px !important;
  border: none
}
.table.default-cell th {
  background-color: rgba(23, 95, 255, 0.1) !important;
}
.error-message {
  margin: -10px 0 20px 0;
  @include text(400, 12px, 16px, $spr-600-color);
}
.options {
  cursor: pointer;
  position: relative;
  .more-options {
    position: absolute;
    background-color: $white;
    border: 1px solid $gray-100;
    border-radius: 4px;
    width: 120px;
    .option {
      padding: 8px 16px;
  @include text(400, 14px, 16px, $spn-900-color);
    }
  }
}
// table thead tr th{
//   height: 28px !important;
//   // background-color: rgba(23, 95, 255, 0.1) !important;
//   background-color: red !important;
// }