@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";

.service-details {
  .content-section {
    
    .tabtitle {
      font-weight: 500 !important;
      color: $spb-900-color;
      letter-spacing: 0.25px !important;
    }
  }

  .pricing {
    width: 70%;
    min-height: 300px;
    left: 280px;
    top: 628px;
    overflow: auto;

    background: $gray-055;
    border: 1px solid $gray-060;
    box-sizing: border-box;
    border-radius: 3px;

    .row-one {
      display: flex;
      border-bottom: 1px solid $gray-060;
      .right {
        border-left: 1px solid $gray-060;
        height: 50%;
        flex: 1;
        height: 100%;
      }

      .left {
        height: 50%;
        flex: 1;
      }
    }
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 400px;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
