@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.links-container {
    border-radius: 8px;
border: 1px solid $spn-100-color;
background: $spn-000-color;
box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.10);

.edit {
  cursor: pointer;
  margin-left: 5px;
  @include text(500, 14px, 16px, $primary-color);
}
.switch {
  display: flex;
  align-items: center;
}
.search-container {
    width: 320px;
    input{
      width: 270px;
      border: none !important;
      height: 34px !important;
    }
    border: 1px solid $spn-200-color;
    position: relative;
    border-radius: 5px;
    height: 42px;
    i {
      position: absolute;
      top: 25%;
      right: 12px;
      color: $gray-550;
    }
  }
}