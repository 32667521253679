@import "../../assets/styles/common/mixins.scss";
@import "../../assets/styles/common/colors.scss";

.toggle-modal-wrapper {
  .content{
    display: flex;
    flex-direction: column;
    align-items: center
  }
  .title{
    @include text(500, 14px, 16px, $spn-700-color);
  }
  .cancel {
    background-color: $gray-400;
  }
  .desc{
    text-align: center;
    @include text(600, 14px, 16px, $spn-700-color);
    margin: 15px 0 25px 0;
  }
  .btns {
    display: flex
  }
}
