@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.chargeback-container{
  border: 1px solid $gray-100;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .action {
    text-align: right;
    color: $primary-color;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .table-items{
    display: flex;
    justify-content: space-between;
    margin: 16px;
  }
  .table-content{
    margin: 16px 16px 82px 16px;
  }
  
  .search-container {
    width: 300px;
    position: relative;
    i {
      position: absolute;
      top: 25%;
      right: 12px;
      color: $gray-550;
    }
  }
  

  .action{
    margin-right: 10px;
    @include text(500, 14px, 16px, $primary-color);
  }
}



@media only screen and (max-width: 680px) {
  
}
