@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";


.collection-overview-container {
  .transaction-count, .transaction-value, .available-balance, .ledge-balance {
    border-radius: 8px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.10);
  }
  .transaction-value {
    background: var(--spgspg-100, $spg-100-color);
  }
  .transaction-count {
    background: var(--sprspr-100, $spr-100-color);
  }
  .available-balance {
    background: var(--spgspg-100, $spo-100-color);
  }
  .ledge-balance {
    background: var(--spbspb-100, $spb-100-color);
  }
  .top-layer{
    margin-bottom: 22px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    .analytics {
      @include text(600, 18px, 16px, $spb-900-color);
    }
    .options {
      display: flex;
    }
    Select, .option {
      height: 40px;
      margin-left: 8px;
      min-width: 112px;
    }
    .onboard-country {
      width: 185px;
      height: 40px;
      margin-left: 8px;
      background: $primary-color;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none
    }
  }
.card-even{
  background: $spg-100-color;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}
.card-odd{
  background: $spo-100-color;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}
.card-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 17px 20px 17px;
border-radius: 8px;
  .card-title {
    @include text(400, 12px, 16px, $spn-610);
  }
  .card-desc {
    @include text(600, 14px, 16px, $dark-180);
  }
}
.graph-container {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @include text(600, 18px, 16px, $black);
    padding: 24px;
  }
  .chart {
    padding: 20px;
  }
  border: 1px solid $gray-100;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 24px;
  .canvasjs-chart-canvas {
    border: none !important;
  }
}
  .mapchart{
    width: 700px;
    height: 700px;
  }
}