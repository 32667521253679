@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.transactions-container{
  border: 1px solid $gray-100;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  // width: 100%;
  .action {
    text-align: right;
    color: $primary-color;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .table-items{
    display: flex;
    justify-content: space-between;
    margin: 16px;
  }
  .dropdown-items {
    display: flex; 
    justify-content: flex-end;
  }
  .table-content{
    margin: 16px 16px 82px 16px;
  }
  
  .search-container {
    width: 320px;
    input{
      width: 270px;
      border: none !important;
      height: 34px !important;
    }
    border: 1px solid $spn-200-color;
    position: relative;
    border-radius: 5px;
    height: 42px;
    i {
      position: absolute;
      top: 25%;
      right: 12px;
      color: $gray-550;
    }
  }
 
  .action{
    @include text(500, 14px, 16px, $primary-color);
  }
 .options{
   margin-right: 59px;
 }
}



@media only screen and (max-width: 1700px) {
  .transactions-container{
    max-width: 81vw;
  }
}

@media only screen and (max-width: 1500px) {
  .transactions-container{
    max-width: 75vw;
  }
}
@media only screen and (max-width: 787px) {
  .transactions-container{
    max-width: 75vw;
    .search-container {
      width: 100%;
      input{
        width: 90%;
      }
    }
    .input-content{
      margin-bottom: 10px;
    }
    .dropdown-items {
      display: flex; 
      flex-direction: column;
      justify-content: flex-end;
    }
    .export-btn{
      width: 100%;
    }
    .table-items {
      display: flex;
      flex-direction: column;
    }
  }
}
