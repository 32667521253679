@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.onboarding-modal-container {
   .cursor {
     cursor: pointer;
   }
  .success {
    padding: 18px;
    max-width: 601px;
    display: flex;
    justify-content: space-evenly;
    @include text(normal, 14px, 14px, $spg-700-color);
    background-color: $spg-100-color;
  }
  .error {
    max-width: 601px;
    padding: 18px;
    display: flex;
    justify-content: space-evenly;
    @include text(normal, 14px, 14px, $spr-600-color);
    background-color: $spr-100-color;
  }
  .choose-country {
    display: flex;
    justify-content: space-between;
    @include text(600, 20px, 16px, $black);
  }
  .available {
    margin: 16px 0 24px;
    @include text(400, 12px, 16px, $dark-100);
  }
  .search-input {
    position: relative;
    .search-icon {
      position: absolute;
      right: 12px;
      top: 12px;
    }
    .form-control {
      border: 1px solid $primary-color !important;
      border-radius: 3px;
    }
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
  }
  .continue {
    margin: 16px 0;
    @include text(400, 12px, 16px, $spn-610);
  }
  .country, .country-active {
    padding: 15px 20px 15px 16px;
    @include text(600, 16px, 24px, $black);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .country-active{
    @include text(600, 16px, 24px, $spn-400-color);
    cursor: not-allowed;
  }
  .country{
    cursor: pointer;
  }
  .country:hover {
    background: $spn-050-color;
  }

  .active {
    @include text(400, 14px, 16px, $spg-500-color);
    background: $spg-100-color;
    border: 1px solid $spg-200-color;
  }

  .pending {
    @include text(400, 14px, 16px, $spo-500-color);
    background: $spo-100-color;
    border: 1px solid $spn-050-color;
  }

  .active, .pending {
    border-radius: 4px;
    margin-left: 8px;
    padding: 4px 8px;
  }
}
.onboarding-container {
  .content-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  .onboard-btn {
    padding: 8px 32px !important;
    font-size: 14px !important;
  }
       .learn-more {
        border: 1px solid $gray-100;
         border-radius: 4px;
         margin-left: 8px;
          @include text(500, 14px, 16px, $spn-610);
       }
      .card-section {
        background: $spn-050-color;
        border: 1px solid $gray-100;
        border-radius: 4px;
    
        .content-paragraph {
          @include text(400, 16px, 24px, $dark-100);
        }
    
        .footnote {
          @include text(500, 14px, 16px, $dark-200);
        }
      }
      .successPage {
        position: fixed;
        top: 60px;
        left: 0;
        background-color: $white;
        width: 100%;
        height: 100%;
        z-index: 100;
        .goto-product {
          @include text(500, 14px, 16px, $primary-color);
          background: rgba(23, 95, 255, 0.1);
          border-radius: 6px;
          padding: 11px 15px;
          display: inline-block;
          margin: 18px 0 0 32px;
          cursor: pointer;
        }
        .submitted {
          margin: 24px 0 8px 0;
          @include text(600, 24px, 30px, $black);
        }
        .submitted-desc {
          @include text(400, 16px, 30px, $spn-500-color);
        }
        .await {
          margin: 8px 0 24px 0; 
          @include text(700, 16px, 30px, $spn-500-color);
          .cur {
          @include text(700, 16px, 30px, $dark-200);
          }
        }
        .success-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        .success-item {
          width: 551px;
        }
      }
}

