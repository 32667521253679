@import "../../assets/styles/common/mixins.scss";
@import "../../assets/styles/common/colors.scss";

.custom-nav-container{
  .sp-navlink {
    padding: 0.5rem;
    position: relative;
    @include text(500, 12px, 16px, $gray-500);
    cursor: pointer;
    &.active {
      border-width: 80%;
      color: $primary-color;
      &::after {
        display: block;
        clear: both;
        content: "";
        position: relative;
        left: 0;
        bottom: 0;
        max-width: 250px;
        height: 1px;
        width: 100%; /* or 100px */
        border-bottom: 2px solid $primary-color;
        margin: 0 auto;
        padding: 4px 0px;
      }
    }
  }
  .arrow-down {
    margin-left: 7px;
  }
  .tab-sub-menu {
    background: $white;
    border: 1px solid $gray-100;
    border-radius: 4px;
    position: absolute;
    margin-top: 10px;
    z-index: 1000;
    width: 180px
  }
  .tab-item {
    @include text(400, 14px, 16px, $spb-900-color);
    padding: 8px 16px;
    cursor: pointer;
  }
  .tab-item-active {
    background: $spn-050-color;
  }
}