@import "../../assets/styles/common/colors.scss";

.pagination-nav > div {
  font-size: 16px;
}
.pagination-nav{
  margin-left: 16px;
}
.pagination-container{
  padding: 0;
  .pagination-item.selected {
    color: $white;
    background-color: $primary-color;
    border-radius: 5px;
  }
}