.table-loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.detail-loader {
  margin-top: -300px;
  margin-bottom: -350px;
}

.loading-dropdown {
  display: block;
  margin-top: -10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: gray;
}

.cl-margin-padding {
  margin: 0;
  padding: 0;
}

.child-row td:first-child {
  padding-left: 35px !important;
}

.grand-child-row td:first-child {
  padding-left: 55px !important;
}
