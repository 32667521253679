@import "../../assets/styles/common/mixins.scss";
@import "../../assets/styles/common/colors.scss";

.view-detail-container {
    .modal-body, .modal-header {
        padding: 0 !important
    }
    .cancel{
        cursor: pointer;
    }
    .item {
        border-bottom: 1px solid $spn-050-color;
        padding: 24px 0;
    }
    .item:last-child {
        border-bottom: none
    }
    .detail-title {
        @include text(600, 20px, 16px, $black);
        padding: 0 24px;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $gray-100;
    }
    .detail-content {
        padding: 0 24px 24px 24px;
    }
}