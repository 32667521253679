@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.add-modal-container {
  .modal-title {
     @include text(600, 18px, 24px, $spb-900-color);
     display: flex;
     justify-content: space-between;
     margin-bottom: 32px
  }
  label {
    @include text(400, 14px, 16px, $dark-100);
  }
  .account-detail {
    padding: 16px 16px 19px 16px;
    background: rgba(23, 95, 255, 0.1);
    border-radius: 4px;
    .name {
       @include text(500, 16px, 16px, $black);
    }
    .number {
      margin-top: 11px;
      @include text(400, 14px, 16px, $black);
   }
  }
  .open-btn {
    width: 100%;
    height: 40px;
    @include text(600, 16px, 24px, $white);

  }
  .subtitle {
    display: flex;
    justify-content: space-between
  }
  .add-account-btn {
    @include text(600, 16px, 24px, $primary-color);
    width: 100% !important;
    border: 1px solid $primary-color;
    border-radius: 4px;
    margin: 20px 0 14px 0;
  }
}
.settlements-container{
  border: 1px solid $gray-100;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .action {
    text-align: right;
    color: $primary-color;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .table-items{
    display: flex;
    justify-content: space-between;
    margin: 16px;
  }
  .table-content{
    margin: 16px 16px 82px 16px;
  }
  
  .search-container {
    width: 300px;
    position: relative;
    i {
      position: absolute;
      top: 25%;
      right: 12px;
      color: $gray-550;
    } 
  }
  
  .action{
    position: relative;
    text-align: left;
    @include text(500, 14px, 16px, $primary-color);
  }
  .more-icon{
    z-index: -1000;
    position: relative;
  }
  .more-options{
    position: absolute;
    background: $white;
    border: 1px solid $gray-100;
    border-radius: 4px;
    z-index: 100000;
    width: 150px;
    text-align: left;
    margin-left: -30px;
    @include text(400, 14px, 16px, $spb-900-color);
    .option{
      padding: 8px 16px;
    }
    .option::nth-child(even){
      background-color: $spn-050-color;
    }
  }
}



@media only screen and (max-width: 680px) {
  
}
