@import "../../assets/styles/common/mixins.scss";
@import "../../assets/styles/common/colors.scss";

.delete-modal-container {
    .modal-title {
        @include text(600, 18px, 24px, $spb-900-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px
     }
    .desc {
        margin: 40px 0 60px 0;
      @include text(400, 14px, 16px, $black);
    }
    .btns{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .cancel-btn {
            cursor: pointer;
            @include text(500, 14px, 16px, $spn-610);
              margin-right: 24px;
          }
          .delete-btn {
              height: 40px;
              width: 150px;
              border-radius: 3px;
          }     
    }
}